<template>
  <div class="flex justify-center align-center" style="height: 100%">
    <h1>欢迎来到</h1>
  </div>
</template>

<script>
export default {
  name: "dashboardView",
  setup(){
    return {
    }
  }
}
</script>

<style scoped>

</style>