import utils from "@/utils/utils";
import router from '@/router/index'
import Utils from "@/utils/utils";
export default {
    namespaced: true,
    state: {
        tabsList: [{
            path: '/dashboard',
            name: '首页',
            icon: 'ri-home-line'
        },
        {
            path: '/project',
            name: '首页项目管理',
            icon: 'ri-home-line'
        }],
        tabActive: "",
    },
    mutations: {
        SET_TABACTIVE: (state, tabActive) => {
            Utils.myPrint('SET_TABACTIVE')
            state.tabActive = tabActive;
        },
        SET_TABSLIST: (state, tabsList) => {
            Utils.myPrint('SET_TABSLIST')

            state.tabsList = tabsList;
        },
    },
    actions: {
        // 登录后重置
        refTabs({commit }) {
            let tabsList = [];
            commit("SET_TABACTIVE", "");
            commit("SET_TABSLIST", tabsList);
            utils.setLocalStorage('tabsList', JSON.stringify(tabsList))
            utils.setLocalStorage("tabActive", "");
        },
        // 初始化
        initTabs({commit }) {
            let tabsList = JSON.parse(localStorage.getItem("tabsList"));
            let tabActive = localStorage.getItem("tabActive");
            if (tabsList && tabActive) {
                commit("SET_TABACTIVE", tabActive);
                commit("SET_TABSLIST", tabsList);
            }
        },
        //添加标签
        addTabs({ state, commit }, route) {
            let tabsList = state.tabsList;
            const isExists = tabsList.some((item) => item.path === route.path);
            if (!isExists) {
                tabsList.push(route);
            }
            commit("SET_TABACTIVE", route.path);
            commit("SET_TABSLIST", tabsList);
            utils.setLocalStorage("tabsList", JSON.stringify(tabsList));
            utils.setLocalStorage("tabActive", route.path);
        },
        //点击标签切换选中
        clickTab({ state, commit }, index) {
            let tab = state.tabsList[index];
            commit("SET_TABACTIVE", tab.path);
            utils.setLocalStorage("tabActive", tab.path);
            router.push({ path: tab.path });
        },
        // 关闭其他标签
        closeOtherTabs({ state, commit }, route) {
            let tabsList = state.tabsList;
            tabsList = tabsList.filter((item) => item.path === route.path);
            commit("SET_TABSLIST", tabsList);
            utils.setLocalStorage("tabsList", JSON.stringify(tabsList));
        },
        // 关闭当前页
        closeCurrentTab({ state, commit }, obj) {
            let tabsList = state.tabsList;
            const index = tabsList.findIndex((item) => item.path === obj.path);
            tabsList.splice(index, 1);
            commit("SET_TABSLIST", tabsList);
            utils.setLocalStorage("tabsList", JSON.stringify(tabsList));
            //打开最后一个tab页面
            commit("SET_TABACTIVE", tabsList[tabsList.length - 1].path);
            utils.setLocalStorage("tabActive", tabsList[tabsList.length - 1].path);
            router.push({ path: tabsList[tabsList.length - 1].path });
        },
    }
}