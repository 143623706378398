import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import utils from './utils/utils'
import store from "./store"
import createStore from "./store/vuexStore";
import moment from "moment";
import waterremark from "@/utils/waterremark";

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import '@/assets/style/common.scss'
import '@/assets/style/color.scss'
import '@/assets/style/layout.css'

const app = createApp(App)

/*
* 逐个添加ElementPlusIcon组件
* */
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$app = utils;

// 添加路由
app.use(router)
// 使用ElementPlus
app.use(ElementPlus,{
    locale: zhCn,}
)
app.use(store)
app.use(createStore)
app.use(moment)
app.use(waterremark)

app.mount('#app')