import { ElMessageBox} from "element-plus";
import {SHOW_LOG} from "@/utils/config";

export default {
    // 判空
    isNull(str) {
        if (str == null || str === "null" || str.length === 0 || /^\s*$/i.test(str)) return true;
        return false;
    },
    // 验证手机号码
    isIphone(str) {
        return !/^1([3456789])\d{9}$/.test(str);
    },
    toFixed(num, fix) {
        return Math.round(num * fix) / fix;
    },
    getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key));
    },
    setLocalStorage(key, value) {
        localStorage.setItem(key, value);
    },
    clearLocalStorage() {
        localStorage.clear();
    },
    formatDate(time, format) { // 格式化时间
        if (time == null || time === "") {
            return ""
        }
        format = format ? format : 'yyyy-MM-dd';
        let date = new Date(time)
        return new Date(date).FormatDataTime(format);
    },
    myPrint(v){
        if (SHOW_LOG){
            console.log(v)
        }
    }
}

export const MyAlert = async (message, showCancel = true, confirm = () => {
}) => {
    await ElMessageBox({
        title: '提示',
        message: message,
        showCancelButton: showCancel,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        beforeClose: (action, instance, done) => {
            confirm(action)
            done()
        }
    })
}

export const MyAlertInput = async (message, confirm = () => {
}) => {
    await ElMessageBox.prompt(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            confirm(value)
        })
        .catch(() => {
        })
}



Date.prototype.FormatDataTime = function (format) {
    if (!format) {
        format = "yyyy-MM-dd HH:mm:ss";
    }
    const o = {
        "M+": this.getMonth() + 1, // month
        "d+": this.getDate(), // day
        "H+": this.getHours(), // hour
        "m+": this.getMinutes(), // minute
        "s+": this.getSeconds(), // second
        "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
        "S": this.getMilliseconds()
        // millisecond
    };
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "")
            .substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return format;
};

/*
* 金额格式化
* */

export const moneyFormat = (num, decimal = 2, split = ',') =>{
    /*
      parameter：
      num：格式化目标数字
      decimal：保留几位小数，默认2位
      split：千分位分隔符，默认为,
      moneyFormat(123456789.87654321, 2, ',') // 123,456,789.88
    */
    if (isFinite(num)) { // num是数字
        if (num === 0) { // 为0
            return num.toFixed(decimal)
        } else { // 非0
            var res = ''
            var dotIndex = String(num).indexOf('.')
            if (dotIndex === -1) { // 整数
                res = String(num).replace(/(\d)(?=(?:\d{3})+$)/g, `$1${split}`) + '.' + '0'.repeat(decimal)
            } else { // 非整数
                // js四舍五入 Math.round()：正数时4舍5入，负数时5舍6入
                // Math.round(1.5) = 2
                // Math.round(-1.5) = -1
                // Math.round(-1.6) = -2
                // 保留decimals位小数
                const numStr = String((Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal)) // 四舍五入，然后固定保留2位小数
                const decimals = numStr.slice(dotIndex, dotIndex + decimal + 1) // 截取小数位
                res = String(numStr.slice(0, dotIndex)).replace(/(\d)(?=(?:\d{3})+$)/g, `$1${split}`) + decimals
            }
            return res
        }
    } else {
        return '--'
    }
}
// Utils.myPrint('result:', moneyFormat(123456789.87654321)) // '123,456,789.88'

