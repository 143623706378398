<template>
  <div class="flex justify-center align-center" style="width: 100vw;height: 100vh;background-color: #000">
    <div>
      <el-image style="width: 368px;height: 40px" :src="require('@/assets/images/logo.png')"></el-image>
      <div style="color: white;margin-top: 60px">
        <div class="flex align-center justify-center" style="text-align: justify;text-align-last: justify;text-justify: inter-ideograph;font-size: 16px;font-weight: bold">
          <div style="text-align: justify;width: 50px;">用户名</div>
          <div style="margin-right: 6px">：</div>
          <div style="width: 130px">
            <el-input size="small" v-model="form.account" style="font-size: 16px" type="text"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-center" style="margin-top: 16px;text-align: justify;text-align-last: justify;text-justify: inter-ideograph;font-size: 16px;font-weight: bold">
          <div style="text-align: justify;width: 50px">密码</div>
          <div style="margin-right: 6px;">：</div>
          <div style="width: 130px">
            <el-input size="small" type="password" v-model="form.password" style="font-size: 16px" :show-password="false"></el-input>
          </div>
        </div>
      </div>
      <div class="flex justify-center align-center" style="margin-top: 16px">
        <div
            style="color: #fff;
        font-size: 16px;
        background-color: #ffb008;
        padding: 5px 16px;
        border-radius: 3px;
        text-shadow: -1px 1px 1px #888;
        cursor:pointer;"
        @click="onLogin">
          登录
        </div>
      </div>
      <div style="height: 40px"></div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import router from "@/router";

export default {
  name: "LoginPage",
  setup() {
    const form = reactive({
      "account": "",
      "password": ""
    })

    async function onLogin() {
      localStorage.setItem("user",'1')
      await router.replace('/home')
    }
    return {
      form,
      onLogin
    }
  }
}
</script>

<style scoped>

</style>