<template>
  <div class="flex justify-start align-start" style="height: calc(100vh - 90px)">
    <div class="" style="width: 225px;background-color: #fff;margin-right: 10px">
      <!--   菜单顶部   -->
      <div class="flex justify-between align-center"
           style="background-color: black;height: 35px;cursor: pointer"
           @click="onHandleMenuTitle">
        <div class="flex justify-start align-center">
          <el-image style="width: 19px;height: 19px;margin-left: 13px" :src="require('@/assets/icons/share.png')" fit="contain"/>
          <div style="font-size: 15px;color: white;margin-left: 8px">常用功能</div>
        </div>
        <el-icon size="16px" style="margin-right: 10px" color="#bbbbbb">
          <CaretTop v-if="!isCollapse"></CaretTop>
          <CaretBottom v-else></CaretBottom>
        </el-icon>
      </div>
      <div v-if="!isCollapse">
        <div v-for="item in menuList" :key="item.name" style="color: #585858;font-size: 15px;padding: 5px;" @click="subClick(item)">
          <div style="height: 35px;cursor: pointer;padding-left: 35px" class="flex justify-start align-center color-change">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div style="width: calc(100vw - 225px - 20px);height: 100% ;background-color: white">
      <div class="flex justify-start align-center" style="height: 35px;background-color: #ededed">

        <div class="flex justify-between align-center"
             :class="{'m_tab_select':currentRoute.index === index,'m_tab':currentRoute.index!==index}"
             style="width: 170px;height: 100%;margin-right: 2px"
             v-for="(item,index) in currentRoute.items" :key="index"
             @click="onTabChange(index)">
          <div style="font-size: 14px;padding-left: 13px"
               >{{ item.name }}</div>
          <el-icon size="24px"
                   style="cursor: pointer;margin-right: 8px"
                   @click.stop="onTabClose(index)"
                   >
            <CloseBold/>
          </el-icon>
        </div>
      </div>
      <div class="flex justify-center align-center">
        <router-view></router-view>
      </div>
    </div>

  </div>
</template>

<script>
import {onMounted, reactive, ref,} from "vue";
import router from "@/router";
import Utils from "@/utils/utils";
import watermark from "@/utils/waterremark";

export default {
  name: "homeIndexPage",
  setup() {

    // 生成菜单
    const menuList = reactive([
      {
        'name': '会员信息综合查询',
        'path': '/404',
      },
      {
        'name': '会籍销售流水查询',
        'path': '/memberAccount',
      },
      {
        'name': '私教销售流水查询',
        'path': '',
      },
      {
        'name': '定金销售流水查询',
        'path': '',

      },
      {
        'name': '访客录入',
        'path': '',

      },
      {
        'name': '会员制卡综合查询',
        'path': '',

      },
      {
        'name': '我的申请',
        'path': '',

      },
    ]);

    // 是否折叠
    const isCollapse = ref(false);

    const currentRoute = reactive({'items': [], 'index': 0});


    /*
    * 点击菜单展开收起
    * */
    function onHandleMenuTitle() {
      Utils.myPrint('onHandleMenuTitle')
      isCollapse.value = !isCollapse.value
    }


    /*
    * 点击菜单子项
    * */
    function subClick(item) {

      if (item.path === '')
        return

      if (currentRoute.items.length === 0) {
        currentRoute.items.push(item)
        currentRoute.index = currentRoute.items.length - 1


      } else {
        // 数组中是否存在
        const flag = ref(-1);
        for (let i = 0; i < currentRoute.items.length; i++) {
          const it = currentRoute.items[i];
          if (it['name'] === item['name']) {
            flag.value = i
            break
          }
        }
        if (flag.value !== -1) {
          currentRoute.index = flag.value
        } else {
          currentRoute.items.push(item)
          currentRoute.index = currentRoute.items.length - 1;
        }

      }
      localStorage.setItem('route_items', JSON.stringify(currentRoute.items))
      localStorage.setItem('route_index', JSON.stringify(currentRoute.index))
      router.push(item.path)
    }

    /*
    * 点击关闭按钮
    * */
    function onTabClose(index) {
      if (currentRoute.items[index].path === '/memberDetail')
        localStorage.setItem('ct','0')
      currentRoute.items.splice(index, 1)
      currentRoute.index = 0
      localStorage.setItem('route_items', JSON.stringify(currentRoute.items))
      localStorage.setItem('route_index', JSON.stringify(currentRoute.index))
      console.log('123')
      console.log(currentRoute.items.length)
      if (currentRoute.items.length === 0){
        router.push('/');
      }else {
        const item = currentRoute.items[currentRoute.index];
        router.push(item.path)
      }
    }

    /*
    * 选择tab
    * */
    function onTabChange(index){
      if (currentRoute.items[index].path === '/memberDetail')
        localStorage.setItem('ct','0')
      currentRoute.index = index
      localStorage.setItem('route_items', JSON.stringify(currentRoute.items))
      localStorage.setItem('route_index', JSON.stringify(currentRoute.index))
      const item = currentRoute.items[currentRoute.index];
      router.push(item.path)
    }

    onMounted(() => {
      const items = JSON.parse(localStorage.getItem('route_items'))
      const index = JSON.parse(localStorage.getItem('route_index'))
      if (items !== undefined && items.length > 0) {
        currentRoute.items = items;
        if (index !== undefined && index !== null) {
          currentRoute.index = parseInt(index);
        }
        router.push(currentRoute.items[currentRoute.index].path)
      }


    })

    onMounted(()=>{
    })
    return {
      isCollapse,
      menuList,
      subClick,
      currentRoute,
      onHandleMenuTitle,
      onTabClose,
      onTabChange
    }
  }
}
</script>

<style lang="scss" scoped>
.color-change:hover {
  background-color: #d9d9d9;
}

.m_tab{
  color: #585858;
  background-color: #d9d9d9;
}
.m_tab:hover{
  color: white;
  background-color: black;
}

.m_tab_select {
  color: white;
  background-color: black;
}

</style>