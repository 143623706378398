import { createRouter, createWebHashHistory } from 'vue-router'

import mainPage from '@/components/mainPage'
import homeIndexPage from '@/components/homeIndexPage'
import notFoundPage from "@/components/notFoundPage";
import LoginPage from "@/pages/LoginPage";
import Utils from "@/utils/utils";
import memberAccount from "@/components/memberAccount";
import dashboardView from "@/components/dashboardView";
import memberDetail from "@/components/memberDetail";

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/homeIndexPage',
    component: mainPage,
    children:[
      {
        path: '/homeIndexPage',
        name: 'homeIndexPage',
        component: homeIndexPage,
        children:[
          {
            path: '/dashboard',
            name: 'dashboard',
            component:dashboardView
          },
          {
            path: '/404',
            name: '404',
            component:notFoundPage,
          },
          {
            path: '/memberAccount',
            name: 'memberAccount',
            component:memberAccount,
          },
          {
            path: '/memberDetail',
            name:'memberDetail',
            component: memberDetail,
          }
        ]
      }
    ]
  },
  {
    path: "/404",
    component: notFoundPage
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  Utils.myPrint('to-->');
  Utils.myPrint(to)
  Utils.myPrint('from-->');
  Utils.myPrint(from)
  const token = localStorage.getItem('user')
  Utils.myPrint(token)
  if (token){
    next()
  }else {
    if(to.fullPath === '/login'){
      next()
    }else {
      next({
        path:"/login",
      });
    }
  }
})

export default router