<template>
  <div  style="width: 100%;padding: 0 10px;position: relative" >
    <div
        style="height: 47px;background-color: #f4f4f4;margin-top: 19px;padding: 0 10px"
        class="flex justify-start align-center">
      <div :class="{'item-class':tabSelect !== index,'select-item-class':tabSelect === index}"
           style="padding: 5px 10px;margin-right: 5px;border-radius: 4px"
           v-for="(item,index) in tabList.list" :key="index">
        {{item}}
      </div>
    </div>
    <div id="ct"  style="margin-top: 10px;border: 8px solid #f4f4f4;padding: 20px" class="flex justify-start align-start">
      <div>
        <div style="width: 250px">
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会员编号</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">BJ0012305050001</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会员卡号</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">BIGA-0030</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">中文姓名</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">Bogo</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">证件类型</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">护照</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">出生日期</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">1996-**-**</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">手机</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">	123********</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px;">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">电子邮件</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px;">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">通讯地址</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px;">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">备注</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px;">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">绑定APP</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">未绑定</div>
          </div>
        </div>
      </div>
      <div>
        <div style="width: 250px">
          <div class="flex justify-start align-center" style="margin-top: 50px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">性别</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">男</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">英文姓名</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">Bogo</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">证件号码</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">410***************</div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">国籍</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">联系电话</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 15px">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">K-Yoga卡号</div>
            <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
          </div>
          <div class="flex justify-start align-center" style="margin-top: 75px;">
            <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">可入场方式</div>
            <div class="" style="font-size: 15px;color: #585858;width: 140px">
              <div>APP二维码</div>
              <div>实体卡/身份证</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="width: 250px;margin-top: 20px" class="flex justify-start align-center">
          <div >
            <el-image style="width: 150px;height: 150px;" :src="require('@/assets/icons/people.png')" fit="contain"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import watermark from "@/utils/waterremark";

export default {
  name: "memberDetail",
  setup(){
    const tabSelect = ref(0)
    const tabList = reactive({
      'list':[
          '个人资料',
          '会籍合同',
          '定金合同',
          '租箱合同',
          '私教合同',
          '商品消费',
          '出入场',
          '私教核销',
          '客诉记录',
          '停卡',
          '补卡',
      ]
    })


    onMounted(()=>{
        // watermark.set("这是水印")
    })

    return {
      tabSelect,
      tabList,
    }
  }
}
</script>

<style lang="scss" scoped>
.item-class{
  background-color: #d9d9d9;
  color: #5a5a5a;
}
.item-class:hover{
  background-color: #fdaf2d;
  color: white;
  cursor: pointer;
}

.select-item-class{
  background-color: black;
  color: white;
}

.select-item-class:hover{
  background-color: #fdaf2d;
  color: white;
  cursor: pointer;
}
</style>