<template>
  <div style="width: 100%;padding: 20px 0">
    <div class="flex justify-start align-start"
         style="background-color: #f4f4f4; margin: 0 10px;padding: 5px 30px 0 30px">
      <div class="flex justify-start align-center">
        <div class="flex justify-start align-center">
          <div style="color: #585858;margin-right: 6px">门店</div>
          <div class="flex justify-start align-center"
               style="background-color: white;height: 25px;width: 158px;padding: 0 4px;border: 1px solid #cccccc">
            HZ002-杭州万象城
          </div>
        </div>
      </div>
      <div class="flex justify-start align-center" style="margin-left: 20px">
        <div style="color: #585858;margin-right: 6px">日期类型</div>
        <div style="height: 25px">
          <select v-model="form.dateType" style="height: 27px;border: 1px solid #cccccc;font-size: 14px" @change="onHandleDateChange">
            <option v-for="(item,index) in dateList.list" :key="index" :value="item.value">{{ item.name }}</option>
          </select>
        </div>
      </div>

      <div class="flex justify-start align-center" style="margin-left: 20px">
        <div style="color: #585858;margin-right: 6px">日期范围</div>
        <div style="height: 25px">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.start_date" size="small" style="height: 27px;width: 125px;font-size: 14px" :clearable="false"></el-date-picker>
        </div>
        <div style="color: #585858;margin:0 4px">~</div>
        <div style="height: 25px">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.end_date" size="small" style="height: 27px;width: 125px;font-size: 14px" :clearable="false"></el-date-picker>
        </div>
      </div>

      <div class="flex justify-start align-center" style="margin-left: 20px">
        <div style="color: #585858;margin-right: 6px">卡种编号</div>
        <div style="height: 25px">
          <input style="border: 1px solid #cccccc;height: 23px;width: 98px;font-size: 14px" v-model="form.cardNo">
        </div>
      </div>
    </div>
    <div class="flex justify-start align-start"
         style="background-color: #f4f4f4; margin:0 10px;padding: 5px 30px 10px 30px">
      <div class="flex justify-start align-center">
        <div style="color: #585858;margin-right: 6px">类型</div>
        <div style="height: 25px">
          <select v-model="form.type" style="height: 27px;border: 1px solid #cccccc;font-size: 14px" @change="onHandleDateChange">
            <option v-for="(item,index) in typeList.list" :key="index" :value="item.value">{{ item.name }}</option>
          </select>
        </div>
      </div>

      <div class="flex justify-start align-center" style="margin-left: 20px">
        <div style="color: #585858;margin-right: 6px">合同号</div>
        <div style="height: 25px">
          <input style="border: 1px solid #cccccc;height: 23px;font-size: 14px" v-model="form.contractNo">
        </div>
      </div>

      <div class="flex justify-start align-center" style="margin-left: 20px">
        <div style="color: #585858;margin-right: 6px">销售员</div>
        <div style="height: 25px">
          <input style="border: 1px solid #cccccc;height: 23px;width: 70px;font-size: 14px" v-model="form.salesman">
        </div>
      </div>

      <div class="flex justify-start align-center" style="margin-left: 25px">
        <div style="height: 25px" class="flex justify-center align-center">
          <input type="checkbox" :id="form.p12" name="salesman" v-model="form.p12">
          <label :for="form.p12">私教12天卡</label>
        </div>

        <div style="height: 25px;margin-left: 35px" class="flex justify-center align-center">
          <input type="checkbox" :id="form.newMark" name="salesman" v-model="form.newMark">
          <label :for="form.newMark">新单标记</label>
        </div>
      </div>

      <div class="flex justify-center align-center" style="margin-left: 25px;margin-top: -1px">
        <div
            style="padding: 5px 15px;border-radius: 8px;color: white;cursor: pointer;"
            class="button-change" @click="onHandleSearch">查询
        </div>
        <div
            style="padding: 5px 15px;border-radius: 8px;color: white;cursor: pointer;margin-left: 20px"
            class="button-change" @click="onHandleSearchTotal">查询总数
        </div>
      </div>
    </div>

    <div v-if="showTotal" style="padding-top: 15px;padding-left: 10px">查询结果总数为:{{ totalValue }}</div>
    <div style="margin: 15px 10px">
      <el-table :data="tableData.list"
                :stripe="true"
                :fit="false" :header-cell-style="{background:'#000000',color:'#ffffff',fontSize:'13px',fontWeight:'normal'}" style="width: 100%">
        <el-table-column align="center" prop="number" label="序号" width="60">
          <template #default="opt">
            <div class="" style="font-size: 16px;font-weight: bold;font-style: italic">
              {{ (currentPage - 1) * 10 + opt.$index + 1 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="门店名称" />
        <el-table-column align="center" prop="contractNo" label="合同编号" width="100px"/>
        <el-table-column align="center" prop="memberName" label="会员姓名" width="100px">
          <template #default="opt">
            <div class="flex justify-center align-center" style="">
              <el-image style="width: 19px;height: 19px;" :src="require('@/assets/icons/user_list.png')" fit="contain"/>
              <div @click="onHandleMember(opt.$index)" class="member_underline" style="color: #e47e31;">{{tableData.list[opt.$index].memberName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="saleDate" label="销售日期" />
        <el-table-column align="center" prop="memberType" label="会籍类型" />
        <el-table-column align="center" prop="saleType" label="销售类别" />
        <el-table-column align="center" prop="startDate" label="开始日期" />
        <el-table-column align="center" prop="endDate" label="结束日期"/>
        <el-table-column align="center" prop="sendDate" label="赠后日期"/>
        <el-table-column align="center" prop="expiryDate" label="终止日期"/>
        <el-table-column align="center" prop="expiryReason" label="终止原因"/>
        <el-table-column align="center" prop="times" label="次卡剩余次数"/>
        <el-table-column align="center" prop="perContractNo" label="原合同号" width="100px"/>
        <el-table-column align="center" prop="price1" label="付款金额">
          <template #default="opt">
            <div class="flex justify-center align-center" style="font-size: 16px;color: #e74c3c">
              <div>{{tableData.list[opt.$index].price1}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="price2" label="定金金额">
          <template #default="opt">
            <div class="flex justify-center align-center" style="font-size: 16px;color: #e74c3c">
              <div>{{tableData.list[opt.$index].price2}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="price3" label="转入金额">
          <template #default="opt">
            <div class="flex justify-center align-center" style="font-size: 16px;color: #e74c3c">
              <div>{{tableData.list[opt.$index].price3}}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 100%;height: 35px;background-color: #d9d9d9"
      class="flex justify-center align-center">
        <div style="font-size: 14px;color: #aaa;margin-right: 5px">首页</div>
        <div style="font-size: 14px;color: #aaa;margin-right: 5px">上页</div>
        <div style="font-size: 14px;color: #aaa;margin-right: 5px">下页</div>
        <div style="font-size: 14px;color: #aaa;margin-right: 5px">末页</div>
        <div style="font-size: 14px;color: #aaa;">第</div>
        <div >
          <input style="width: 25px;height: 10px;text-align: center" v-model="currentPage">
        </div>
        <div style="font-size: 14px;color: #aaa;">页</div>
      </div>
    </div>
  </div>

  <el-dialog
      v-model="dialogVisible"
      :show-close="false"
      width="30%"
      top="29vh"
      style="border: 6px solid #dddddd"
  >
    <template #header>
      <div class="flex justify-between align-center"
           style="padding: 5px 10px;background-color: black;color: white">
        <div style="font-size: 16px;font-weight: bold">会员信息</div>
        <div>
          <el-icon size="24px"
                   style="cursor: pointer;"
                   @click.stop="dialogVisible = false"
          >
            <CloseBold/>
          </el-icon>
        </div>
      </div>
    </template>
    <div class="flex justify-between align-center">
      <div style="width: 40%;" class="flex justify-center align-center">
        <div >
          <el-image style="width: 120px;height: 120px;" :src="require('@/assets/icons/people.png')" fit="contain"/>
          <div class="flex justify-center align-center member-detail" @click="onHandleMemberDetail">会员详情</div>
        </div>
      </div>
      <div style="width: 60%">
        <div class="flex justify-start align-center" style="margin-top: 15px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会员编号</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">BJ0012305050001</div>
        </div>
        <div class="flex justify-start align-center" style="margin-top: 15px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会员卡号</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">BIGA-0030</div>
        </div>
        <div class="flex justify-start align-center" style="margin-top: 15px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会员姓名</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">Bogo</div>
        </div>
        <div class="flex justify-start align-center" style="margin-top: 15px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">性别</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">男</div>
        </div>
        <div class="flex justify-start align-center" style="margin-top: 15px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会籍类型</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">快团团</div>
        </div>
        <div class="flex justify-start align-center" style="margin-top: 15px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">会员有效期</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px">2023-05-12</div>
        </div>
        <div class="flex justify-start align-center" style="margin-top: 15px;margin-bottom: 35px">
          <div class="flex justify-end align-center" style="font-size: 15px;color: #585858;width: 100px;font-weight: bold;margin-right: 10px">备注</div>
          <div class="flex justify-start align-center" style="font-size: 15px;color: #585858;width: 140px"></div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {reactive, ref} from "vue";
import moment from "moment";
import Utils from "@/utils/utils";

export default {
  name: "memberAccount",
  setup() {
    let td = moment().subtract(0, 'days').format('YYYY-MM-DD')
    let td2 = moment().subtract(1, 'days').format('YYYY-MM-DD')

    const showTotal = ref(false)
    const totalValue = ref(13)

    const dialogVisible = ref(false)

    const tableData = reactive({
      'list': [
        {
          'name': '北京建国',
          'contractNo': 'BJ001200248',
          'memberName':'bogo',
          'saleDate':'2023-05-05',
          'memberType':'快团团',
          'saleType':'新会员',
          'startDate':'2023-05-05',
          'endDate':'2023-05-12',
          'sendDate':'2023-08-12',
          'expiryDate':'',
          'expiryReason':'',
          'times':9,
          'perContractNo':'BJ001200246',
          'price1':3000,
          'price2':0,
          'price3':0
        },
        {
          'name': '北京建国',
          'contractNo': 'BJ001200248',
          'memberName':'bogo',
          'saleDate':'2023-05-05',
          'memberType':'快团团',
          'saleType':'新会员',
          'startDate':'2023-05-05',
          'endDate':'2023-05-12',
          'sendDate':'2023-08-12',
          'expiryDate':'',
          'expiryReason':'',
          'times':9,
          'perContractNo':'BJ001200246',
          'price1':3000,
          'price2':0,
          'price3':0
        },
        {
          'name': '北京建国',
          'contractNo': 'BJ001200248',
          'memberName':'bogo',
          'saleDate':'2023-05-05',
          'memberType':'快团团',
          'saleType':'新会员',
          'startDate':'2023-05-05',
          'endDate':'2023-05-12',
          'sendDate':'2023-08-12',
          'expiryDate':'',
          'expiryReason':'',
          'times':9,
          'perContractNo':'BJ001200246',
          'price1':3000,
          'price2':0,
          'price3':0
        },
        {
          'name': '北京建国',
          'contractNo': 'BJ001200248',
          'memberName':'bogo',
          'saleDate':'2023-05-05',
          'memberType':'快团团',
          'saleType':'新会员',
          'startDate':'2023-05-05',
          'endDate':'2023-05-12',
          'sendDate':'2023-08-12',
          'expiryDate':'',
          'expiryReason':'',
          'times':9,
          'perContractNo':'BJ001200246',
          'price1':3000,
          'price2':0,
          'price3':0
        },
      ],
      'total': 0
    })
    const currentPage = ref(1)

    const form = reactive({
      'dateType': '1',
      'type': '1',
      'contractNo': '',
      'salesman': '',
      'p12': false,
      'newMark': false,
      'start_date': td2,
      'end_date': td,
      'cardNo': '',
    })

    // 日期类型
    const dateList = reactive({
      'list': [
        {
          'name': '销售日期',
          'value': "1"
        },
        {
          'name': '计佣日期',
          'value': "2"
        }
      ]
    })

    // 类型
    const typeList = reactive({
      'list': [
        {
          'name': '全部',
          'value': "1"
        },
        {
          'name': '新+升+续',
          'value': "2"
        },
        {
          'name': '新会员',
          'value': "3"
        },
        {
          'name': '升级',
          'value': "4"
        },
        {
          'name': '续会',
          'value': "5"
        },
        {
          'name': '转让',
          'value': "6"
        },
        {
          'name': '转店',
          'value': "7"
        },
        {
          'name': '停卡结束',
          'value': "8"
        },
        {
          'name': '加赠',
          'value': "9"
        },
        {
          'name': '后台调整',
          'value': "10"
        },
        {
          'name': '复原合同',
          'value': "11"
        },
        {
          'name': '客诉退款',
          'value': "12"
        },
        {
          'name': '转换',
          'value': "13"
        },
      ]
    })

    function onHandleDateChange() {
      console.log(form.dateType)
    }

    function onHandleSearch() {
      alert(JSON.stringify(form))
    }

    function onHandleSearchTotal() {
      showTotal.value = true
    }

    function onHandleMember(index){
      Utils.myPrint(index)
      dialogVisible.value = true
    }


    function onHandleMemberDetail() {
      console.log('123')
      const currentRoute = reactive({
        'items':[],
        'index':0,
      })
      const item = {
        'name': '会员信息',
        'path': '/memberDetail',
      }
      const items = JSON.parse(localStorage.getItem('route_items'));
      const index = parseInt(JSON.parse(localStorage.getItem('route_index')));
      currentRoute.items = items
      currentRoute.index = index

      if (currentRoute.items.length === 0) {
        currentRoute.items.push(item)
        currentRoute.index = currentRoute.items.length - 1
        localStorage.setItem('route_items', JSON.stringify(currentRoute.items))
        localStorage.setItem('route_index', JSON.stringify(currentRoute.index))
      } else {
        // 数组中是否存在
        const flag = ref(-1);
        for (let i = 0; i < currentRoute.items.length; i++) {
          const it = currentRoute.items[i];
          if (it['name'] === item['name']) {
            flag.value = i
            break
          }
        }
        if (flag.value !== -1) {
          currentRoute.index = flag.value
        } else {
          currentRoute.items.push(item)
          currentRoute.index = currentRoute.items.length - 1;
        }
        console.log(currentRoute)
        localStorage.setItem('route_items', JSON.stringify(currentRoute.items))
        localStorage.setItem('route_index', JSON.stringify(currentRoute.index))
        location.reload()
      }
    }
    return {
      dateList,
      typeList,
      form,
      onHandleDateChange,
      onHandleSearch,
      onHandleSearchTotal,
      showTotal,
      totalValue,
      tableData,
      currentPage,
      onHandleMember,
      dialogVisible,
      onHandleMemberDetail
    }
  }
}
</script>

<style lang="scss">
.button-change {
  background-color: black;
}

.button-change:hover {
  background-color: #fdaf2d;
}

.header-row-class{
  background-color: black;
}

.el-table tbody {
  color: #585858;
  font-size: 12px;
  padding: 0;
}
.el-table tbody tr:hover>td {
  background: #f6e6c7 !important;
  color: #000000;
}

.member_underline:hover{
  text-decoration: underline;
  cursor: pointer;
}
.el-dialog__header {
  padding: 0!important;
  margin: 0!important;
}
.el-dialog__body{
  padding: 0!important;
  margin: 0!important;
}

.member-detail{
  color: blue;
}
.member-detail:hover{
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

</style>