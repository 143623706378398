<template>
  <div class="view-home">
    <div class="home-header flex justify-start align-center">
      <div class="logo">
        <el-image style="width: 220px;height: 70px" :src="require('@/assets/images/logoh.png')" fit="contain" />
      </div>
      <div v-if="!moveUserBar" style="color: white;font-size: 12px;height: 100%;padding: 0 8px" class="flex justify-center align-center">
        <div class="flex justify-center align-center" style="margin-left: 8px">
          <el-image style="width: 25px;height: 25px" :src="require('@/assets/icons/user.png')" fit="contain" />
          <div style="margin-left: 5px">
            <div>郭06152</div>
            <div>06152</div>
          </div>
        </div>

        <div class="flex justify-center align-center" style="margin-left: 8px">
          <el-image style="width: 25px;height: 25px" :src="require('@/assets/icons/address.png')" fit="contain" />
          <div style="margin-left: 5px" class="flex justify-center align-center">
            <div>北京建国</div>
            <div style="cursor: pointer" @click="onHandleChange">（<span style="text-decoration: underline">更换</span>）</div>
          </div>
        </div>

        <div class="flex justify-center align-center">
          <div style="color: #5f5f5f">|</div>
          <div style="font-size: 14px;margin-left: 6px;cursor: pointer">门店通知</div>
          <div class="flex justify-center align-center"
               style="border-radius: 100px;background-color: #e54d42;width: 25px;height: 25px;font-style: italic;margin-left: 6px">0
          </div>
          <div class="flex justify-center align-center" style="margin-left: 6px;cursor: pointer" @click="onHandleMove">
            <el-icon size="20px"><ArrowDown /></el-icon>
          </div>
        </div>
      </div>
      <div v-else style="width: 355px"></div>
      <div class="flex justify-start align-center" style="margin-left: 90px;height: 100%">
        <div class="text-cur flex justify-center align-center" @mouseenter="onMouseOver(1)" @mouseleave="onMouseMove(1)" @click="onMouseOver(1)">会籍</div>
        <div class="text-cur flex justify-center align-center" @mouseenter="onMouseOver(2)" @mouseleave="onMouseMove(2)">私教</div>
        <div class="text-cur flex justify-center align-center" @mouseenter="onMouseOver(3)" @mouseleave="onMouseMove(3)">商品管理</div>
        <div class="text-cur flex justify-center align-center" @mouseenter="onMouseOver(4)" @mouseleave="onMouseMove(4)">财务</div>
        <div class="text-cur flex justify-center align-center" @mouseenter="onMouseOver(5)" @mouseleave="onMouseMove(5)">客诉</div>
      </div>
    </div>
    <div v-if="moveUserBar" style="position: absolute;background-color: black;left: 238px;top: 80px ;height: 60px;z-index: 10;border: 2px solid #fdaf2d">
      <div style="color: white;font-size: 12px;height: 100%;padding: 0 8px" class="flex justify-center align-center">
        <div class="flex justify-center align-center" style="margin-left: 8px">
          <el-image style="width: 25px;height: 25px" :src="require('@/assets/icons/user.png')" fit="contain" />
          <div style="margin-left: 5px">
            <div>郭06152</div>
            <div>06152</div>
          </div>
        </div>

        <div class="flex justify-center align-center" style="margin-left: 8px">
          <el-image style="width: 25px;height: 25px" :src="require('@/assets/icons/address.png')" fit="contain" />
          <div style="margin-left: 5px" class="flex justify-center align-center">
            <div>北京建国</div>
            <div style="cursor: pointer" @click="onHandleChange">（<span style="text-decoration: underline">更换</span>）</div>
          </div>
        </div>

        <div class="flex justify-center align-center">
          <el-image style="width: 25px;height: 25px" :src="require('@/assets/icons/people.png')" fit="contain" />
          <div style="margin-left: 5px" class="flex justify-center align-center">
            <div style="cursor: pointer">销售-店长</div>
          </div>
        </div>

        <div class="flex justify-center align-center" style="margin-left: 8px" @click="onHandleQuit">
          <el-image style="width: 25px;height: 25px" :src="require('@/assets/icons/quit.png')" fit="contain" />
          <div style="margin-left: 5px" class="flex justify-center align-center">
            <div style="cursor: pointer">退出</div>
          </div>
        </div>

        <div class="flex justify-center align-center" style="margin-left: 46px">
          <div style="font-size: 14px;margin-left: 6px;cursor: pointer">帮助中心</div>
          <div style="color: #5f5f5f;margin-left: 5px">|</div>
          <div style="font-size: 14px;margin-left: 6px;cursor: pointer">修改密码</div>
          <div style="color: #5f5f5f;margin-left: 5px">|</div>
          <div style="font-size: 14px;margin-left: 5px;cursor: pointer">门店通知</div>
          <div class="flex justify-center align-center"
               style="border-radius: 100px;background-color: #e54d42;width: 25px;height: 25px;font-style: italic;margin-left: 6px">0
          </div>
          <div class="flex justify-center align-center" style="margin-left: 6px;cursor: pointer" @click="onHandelMoveBack">
            <el-icon size="20px"><ArrowUp /></el-icon>
          </div>
        </div>
      </div>
    </div>
    <div v-if="module1" style="position: absolute;z-index: 11;background-color: #ededed;left:685px;border: 5px solid #fdaf2d;font-size: 15px">
      <div class="flex justify-center align-start" style="padding: 10px">
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">会籍查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会员信息综合查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会籍销售流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会员名单查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">定金销售流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会员停转补流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">支付宝支付流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会员出入场流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会员制卡综合查询</div>
        </div>
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">会籍业务</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">非活跃会员跟进</div>
        </div>
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">会籍接待</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">访客录入</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会籍礼品领取</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">门店运营日报</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">非会员门店预约</div>
        </div>
      </div>
    </div>

    <div v-if="module2" style="position: absolute;z-index: 11;background-color: #ededed;left:755px;border: 5px solid #fdaf2d;font-size: 15px">
      <div class="flex justify-center align-start" style="padding: 10px">
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">私教业务</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教合同办理</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教定金销售</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">上课预约及取消预约</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">上课核销</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">会员更换教练</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教礼包销售</div>
        </div>
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">私教查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教销售流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教预约核销流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教日程安排一览表</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教余课一览表</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教消耗历史查询表</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">小团操预约核销流水查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教小票签名查询</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">私教评分流水查询</div>
        </div>
      </div>
    </div>

    <div v-if="module3" style="position: absolute;z-index: 11;background-color: #ededed;left:825px;border: 5px solid #fdaf2d;font-size: 15px">
      <div class="flex justify-center align-start" style="padding: 10px">
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">进销存业务</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">商品盘点</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">库存余额一览表</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">商品进销存报表</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">商品销售流水</div>
        </div>
      </div>
    </div>

    <div v-if="module4" style="position: absolute;z-index: 11;background-color: #ededed;left:925px;border: 5px solid #fdaf2d;font-size: 15px">
      <div class="flex justify-center align-start" style="padding: 10px">
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">财务管理</div>
        </div>
      </div>
    </div>

    <div v-if="module5" style="position: absolute;z-index: 11;background-color: #ededed;left:995px;border: 5px solid #fdaf2d;font-size: 15px">
      <div class="flex justify-center align-start" style="padding: 10px">
        <div>
          <div class="flex justify-start align-center" style="background-color: #585858;color: white;width: 170px;height: 30px;padding:0 10px">客诉管理</div>
          <div class="flex justify-start align-center item-class" style="color: #585858;width: 170px;height: 30px;padding:0 12px;border-bottom: 1px solid #e1e1e1">客诉综合查询</div>
        </div>
      </div>
    </div>
    <div class="home-cnt">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRefs,} from "vue";
import router from "@/router";
// import rm from '@/utils/roomInfo'
import Utils from "@/utils/utils";

export default {
  name: "mainPage",
  setup() {
    // 获取用户信息
    const userInfo = reactive(JSON.parse(localStorage.getItem('userInfo')))
    // 门店id
    const roomId = ref(localStorage.getItem('roomId'))
    // 获取门店列表
    const roomList = JSON.parse(localStorage.getItem('rooms'))

    const moveUserBar = ref(false)

    // 当前门店信息
    let currentRoom = reactive({'value':{}})
    for (const index in roomList) {
      const item = roomList[index]
      if(item['salesRoomId'].toString() === roomId.value){
        localStorage.setItem('currentRoom',JSON.stringify(item))
        currentRoom.value = item;
      }
    }

    // /*
    // * 改变当前门店
    // * */
    // async function handleChangeRoom(item){
    //   const res = await adminSetLastRoom({'roomId':item['salesRoomId']})
    //   if (res){
    //     rm.setCurrentRoomInfo(item)
    //     rm.setCurrentRoomId(item['salesRoomId'].toString())
    //     currentRoom.value = rm.getCurrentRoomInfo()
    //     Utils.myPrint(JSON.stringify(currentRoom.value))
    //     await router.replace('/home')
    //     router.go(0)
    //   }
    // }
    /*
    * 监听当前门店是否发生变化
    * */
    // watch(currentRoom,(newValue)=>{
    //   alert('值发生了变化'+ JSON.stringify(newValue))
    // })

    // 是否显示忘记密码 目前已注释
    const forgetPwdObj = reactive({isOpen: false})

    const logo = ref('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg')

    /*![]
    * 修改信息
    * */
    function onEditUser() {
      Utils.myPrint('修改信息');
    }

    /*
    * 重置密码
    * */
    function onChangePwd() {
      Utils.myPrint('重置密码');
    }

    /*
    * 退出登录
    * */
    function onHandleQuit() {
      Utils.myPrint('退出登录');
      localStorage.removeItem('user');
      router.replace('/login');
    }

    function onHandleChange(){
      alert('更换门店')
    }

    function onHandleMove(){
      moveUserBar.value =true
    }

    function onHandelMoveBack(){
      moveUserBar.value = false
    }

    const module1 = ref(false)
    const module2 = ref(false)
    const module3 = ref(false)
    const module4 = ref(false)
    const module5 = ref(false)

    function onMouseOver(index){
      console.log('onMouseOver->'+index)
      switch (index) {
        case 1:
          module1.value = true
          break
        case 2:
          module2.value = true
          break
        case 3:
          module3.value = true
          break
        case 4:
          module4.value = true
          break
        case 5:
          module5.value = true
          break
      }
    }
    function onMouseMove(index){
      switch (index) {
        case 1:
          module1.value = false
          break
        case 2:
          module2.value = false
          break
        case 3:
          module3.value = false
          break
        case 4:
          module4.value = false
          break
        case 5:
          module5.value = false
          break
      }

    }
    onMounted(() => {
      Utils.myPrint('mainPage')
    })
    return {
      userInfo,
      currentRoom,
      roomList,
      // handleChangeRoom,
      ...toRefs(forgetPwdObj),
      onEditUser,
      onChangePwd,
      logo,
      onHandleChange,
      onHandleMove,
      onHandelMoveBack,
      moveUserBar,
      onHandleQuit,
      onMouseOver,
      onMouseMove,
      module1,
      module2,
      module3,
      module4,
      module5,
    }
  }
}
</script>

<style lang="scss" scoped>
.view-home {
  height: 100%;
  width: 100%;

  .home-header {
    width: 100%;
    height: 70px;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #000;

    .logo {
      width: 220px;
      height: 70px;
    }

    .el-dropdown {
      cursor: pointer;
    }
  }

  .home-cnt {
    background-color: #ededed;
    padding: 10px;
  }

  .text-cur{
    height: 100%;
    padding: 0 20px;
    background-color: black;
    color: white;
    font-size: 15px;
  }

  .text-cur:hover{
    height: 100%;
    padding: 0 20px;
    background-color: #fdaf2d;
    color: white;
    font-size: 15px;
    cursor: pointer;
  }

}
</style>